/** @format */

"use client";
import React, { useEffect, useMemo } from "react";
import "./page.css";

const ContactForm = () => {
  const memoizedIframe = useMemo(
    () => (
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.5546002811484!2d-79.32585682334411!3d43.74057874677434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cd8f9cb0c3b1%3A0xd51267a834d31c85!2s1315%20Lawrence%20Ave%20E%20%23511%2C%20North%20York%2C%20ON%20M3A%203R3%2C%20Canada!5e0!3m2!1sen!2sin!4v1732019508087!5m2!1sen!2sin"
          width="600"
          height="350"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    ),
    []
  );

  useEffect(() => {
    const loadHubSpotForm = () => {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "39930342",
            formId: "c599e65a-a8bc-4e28-ac82-cc8116fb417d",
            target: "#hubspotFormContainer",
            onFormSubmitted: () => {
              window.location.href = "/thankyou";
            },
          });
        }
      };
      document.body.appendChild(script);
    };

    loadHubSpotForm();
  }, []);

  return (
    <section className="ContactSection">
      <div className="container containerxxl">
        <div className="topsection">
          <h3>Contact us</h3>
          <p>
            For more information about our services, or to request a quote, give
            us a call! You can also fill
            <br /> out our contact form, and we will get back to you within one
            business day.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="LeftsideAddress">
              <h4>LOCATION</h4>
              <label>
                1315 Lawrence Avenue East Unit 511
                <br />
                Toronto, Ontario M3A 3R3
                <br />
                647-993-5911
              </label>
              {memoizedIframe}
            </div>
          </div>
          <div className="col-md-6" id="Pointtocontactus">
            <div className="Letstalk">
              <div className="title_Section"></div>
              <div id="hubspotFormContainer"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="min_height"></div>
    </section>
  );
};

export default ContactForm;
