/** @format */

"use client";
import React, { useEffect, useState } from "react";
import "./Footer.css";
import { apiService } from "@/services/api.services";
import LazyImage from "../../../src/app/LazyImage";
import Link from "next/link";
import instagram from "@/Helper/images/instagram.svg";
import message from "@/Helper/images/message.svg";

const ScrollToAnchor = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href');
    const target = document.querySelector(targetId);

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };
};

const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const [error, setError] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    async function fetchFooterData() {
      try {
        const resp = await apiService.getAllUtilityData();
        if (resp.status === 200) {
          setFooterData(resp.data?.data?.attributes);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err);
      }
    }

    fetchFooterData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const distanceFromBottom = document.documentElement.scrollHeight - window.innerHeight - window.scrollY;
      setIsButtonVisible(distanceFromBottom > 800);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <footer className="footer_area ">
      <div className="container">
        <div className="footer_container">
          <p>{footerData?.rights_reserved}</p> 
          <Link className="lefticons" href="mailto:juwan@standarddemo.ca">
            <LazyImage
              src={message.src}
              alt="Image of FULL DECONSTRUCTION services"
              className="lazy"
            />
          </Link>
          <Link
            target="_blank"
            className="lefticons"
            href="https://www.instagram.com/standarddemolition/"
          >
            <LazyImage
              src={instagram.src}
              alt="Image of FULL DECONSTRUCTION services"
              className="lazy"
            />
          </Link>
        </div>
      </div>
      {isButtonVisible && (
        <div className="CallBackButton">
          <Link href="#Pointtocontactus">Request More Information</Link>
        </div>
      )}
    </footer>
  );
};

export default Footer;
