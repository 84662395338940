/** @format */

"use client";
import "./NavBar.css";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import logo_img from "@/Helper/images/STANDARD DEMOLITION.png";

const NavBar = () => {
  const [isOnScroll, setIsOnScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsOnScroll(true);
      } else {
        setIsOnScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`nav_area ${isOnScroll ? "onscroll_bg" : ""}`}>
      <div className="container">
        <nav className="nav_container">
          {/* Logo on the left */}
          <div className="nav_container_left">
            <Link href={"/"} key={"logo"}>
              <Image
                src={logo_img}
                alt="logo"
                width={100} 
                height={40}
                className="nav_logo"
              />
            </Link>
          </div>

          {/* Phone section on the right */}
          <div className="nav_container_right">
            <a href="tel:6479935911" className="phone_button" aria-label="Call us">
              <span className="phone_text">647-993-5911</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
